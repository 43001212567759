import http from "@/services/http.service";
import {
  MEMBERS,
  SERVICES,
  MEMBER_CHANGE_PASSWORD,
  MEMBER_SOCIAL_PROFILE,
  MEMBERSLIST,
  GET_ALL_USER,
  GET_CHAT_USER,
  ROOMS,
  IMAGE_UPLOAD
} from "@/constants/apiUrls";
import { toUsPhone } from "../utils/number.utility.js";
import axios from "axios";
import tokenService from "./token.service.js";

const getMembers = async (commit, payload) => {
  const response = await http.get(MEMBERS, {
    params: { type: "members", ...payload, user_type: 1 },
  });
  response.data.data.forEach((item) => {
    item.user_detail.company_phone = item.user_detail
      ? toUsPhone(item.user_detail.company_phone)
      : "";
    item.user_detail.representative_phone = item.user_detail
      ? toUsPhone(item.user_detail.representative_phone)
      : "";
    item.roomId = item.rooms.length ? item.rooms[0].id : "";
  });
  commit("setMembers", response.data);
  commit("setMeta", response.data);
};

const getMembersAndAssociates = async (commit) => {
  const response = await http.get(GET_ALL_USER, {
    params: { type: "all" },
  });
  response.data.data.forEach((item) => {
    item.user_detail.company_phone = item.user_detail
      ? toUsPhone(item.user_detail.company_phone)
      : "";
    item.user_detail.representative_phone = item.user_detail
      ? toUsPhone(item.user_detail.representative_phone)
      : "";
  });
  commit("setMembersAndAssociates", response.data);
};

const getMembersList = async (commit) => {
  const response = await http.get(MEMBERSLIST, {
    params: { type: "members", user_type: 1 },
  });
  commit("setMembersList", response.data);
};

const getMembersAssociateList = async (commit) => {
  const response = await http.get(MEMBERSLIST);
  response.data.data.forEach((item) => {
    item.companyRepName = item.company_name + " / " + item.representative_name;
  });
  commit("setMembersAssociateList", response.data);
};

const createMember = async (commit, payload) => {
  await http.post(MEMBERS, { ...payload, user_type: 1 });
};

const deleteMember = async (commit, payload) => {
  await http.delete(MEMBERS + "/" + payload.id);
};

const updateMember = async (commit, payload) => {
  await http.put(MEMBERS + "/" + payload.id, { ...payload, user_type: 1 });
};

const getMemberServices = async (commit) => {
  const response = await http.get(SERVICES);
  commit("setMemberServices", response.data);
};

const createAssociate = async (commit, payload) => {
  await http.post(MEMBERS, { ...payload, user_type: 2 });
};

const activateMember = async (commit, payload) => {
  await http.put(MEMBERS + "/" + payload.id + "/activation", {
    ...payload,
    user_type: 1,
  });
};

const updateMemberPassword = async (commit, payload) => {
  await http.put(MEMBER_CHANGE_PASSWORD + "/" + payload.id + "/password", {
    ...payload,
    user_type: 1,
  });
};

const updateSocialProfile = async (commit, payload) => {
  await http.put(MEMBER_SOCIAL_PROFILE + "/" + payload.id + "/social-profile", {
    ...payload,
    user_type: 1,
  });
};

const getRoomList = async (commit) => {
  const response = await http.get(ROOMS);
  commit("setRoomList", response.data);
};

const users = async (commit, query) => {
  const response = await http.get(GET_CHAT_USER, { params: query });
  commit("setUsers", response.data);
};

const uploadImage = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_MEDIA_UPLOAD_SERVER + IMAGE_UPLOAD,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setImageResponse", response);
};

export default {
  getMembers,
  createMember,
  deleteMember,
  updateMember,
  getMemberServices,
  createAssociate,
  activateMember,
  updateMemberPassword,
  updateSocialProfile,
  getMembersList,
  getMembersAssociateList,
  getMembersAndAssociates,
  users,
  getRoomList,
  uploadImage,
};
