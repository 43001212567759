<template>
  <v-dialog v-model="dialog" persistent max-width="750px">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("member.socialProfile") }}
        </span>
        <v-spacer></v-spacer>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.facebook" :label="$t('member.facebook')"
                :placeholder="$t('member.facebook')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.instagram" :label="$t('member.instagram')"
                :placeholder="$t('member.instagram')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.x" :label="$t('member.x')"
                :placeholder="$t('member.x')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.ebay" :label="$t('member.ebay')"
                :placeholder="$t('member.ebay')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.craigslist" :label="$t('member.craigslist')"
                :placeholder="$t('member.craigslist')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.tiktok" :label="$t('member.tiktok')"
                :placeholder="$t('member.tiktok')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.website" :label="$t('member.website')"
                :placeholder="$t('member.website')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.company_reviews_link"
                :label="$t('member.google_review_link')" :placeholder="$t('member.google_review_link')"
                hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="selectedMember.user_detail.carparts" :label="$t('member.carparts')"
                :placeholder="$t('member.carparts')" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="mb-3 v-label">{{ $t('member.company_introduction') }}</div>
              <vue-editor v-model="selectedMember.user_detail.company_introduction" :editorToolbar="companyIntroToolbar"
                 :placeholder="$t('member.company_introduction')"></vue-editor>
            </v-col>
            <v-col cols="12">
              <div
                class="flex w-full h-screen items-center justify-center text-center fileupload custom-fileUpload"
                id="app"
                style="min-height: 56px; margin-bottom: 15px"
              >
                <v-progress-circular
                  v-if="fileLoader"
                  indeterminate
                  :size="90"
                  color="green"
                ></v-progress-circular>
                <div class="" v-if="!fileLoader">
                  <image-uploader
                    ref="company_banner"
                    id="fileInput"
                    :quality="0.9"
                    outputFormat="verbose"
                    :preview="false"
                    :className="['d-none']"
                    @input="onFileChange($event, 'company_banner')"
                  >
                    <label
                      for="fileInput"
                      slot="upload-label"
                      class="node-image-uploader"
                    >
                      <figure>
                        <img src="../../assets/dummy-img.svg" alt="" />
                      </figure>
                      <span class="upload-caption">{{
                        $t("member.clickToUpload")
                      }}</span>
                    </label>
                  </image-uploader>
                </div>
                <div id="company_banner" class="select-post-image">
                  <img
                    v-if="image"
                    class="company_banner"
                    :src="image?.url100"
                    alt=""
                    style="border-radius: 8px"
                  />
                  <a
                    id="img-delIcon"
                    v-if="image"
                    @click="deleteImage('company_banner')"
                    class="delicon"
                    data-tooltip="Delete">
                    <!-- <img src="./../assets/img/trash.svg" alt="" /> -->
                    <v-icon>mdi-delete</v-icon>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" dark dense @click="close">
          {{ $t("member.cancel") }}</v-btn>
        <v-btn color="primary" :loading="updating" @click="update">
          Save
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { showSnackbar } from "../../eventbus/action.js";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  name: "SocialProfile",
  props: {
    dialogStatus: {
      required: true,
      type: Boolean,
      default: false,
    },
    selectedUser: {
      required: false,
      type: Object,
      default: () => {
        return { user_detail: {} };
      },
    },
  },

  data() {
    return {
      updating: false,
      dialog: this.dialogStatus,
      selectedMember: this.selectedUser,
      companyIntroToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike", "link"]
      ],
      companyBanner: new File(["foo"], "foo.txt", {
        type: "text/plain",
      }),
      fileLoader: false,
      image: false,
    };
  },
  async mounted() {
    if (this.selectedMember.medias.length > 0) {
      this.image = this.selectedMember.medias[0];
    }
  },
  computed: {
    ...mapGetters({
      imageResponse: "members/getImageResponse",
    }),
  },
  methods: {
    ...mapActions({
      updateSocialProfile: "members/updateSocialProfile",
      uploadImage: "members/uploadImage",
    }),

    close() {
      this.dialog = false;
      this.$emit("close", false);
    },

    async update() {
      try {
        this.updating = true;
        let payload = _.cloneDeep(this.selectedMember);
        var media_ids = this.image ? this.image.id : "";
        if (media_ids != "") {
          payload = {
            ...payload,
            media_ids: [media_ids],
          };
        }
        await this.updateSocialProfile(payload);
        this.$emit("refresh");
        await this.close();
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object"
            ? Object.values(message)[0][0]
            : message;
        showSnackbar(errorMessage);
      } finally {
        this.updating = false;
      }
    },

    async onFileChange(fileData, type) {
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image = selectedImage.data;
    },

    async saveImage(file, tag) {
      try {
        this.fileLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await this.uploadImage(formData);
        this.fileLoader = false;
        return this.imageResponse;
      } catch ({ message }) {
        this.$refs[tag].$el.children[1].value = "";
        this.apiErrorMessage = message;
      }
    },

    deleteImage(type) {
      this.$refs[type].$el.children[1].value = "";
      this.image = false;
      if (this.isAttentionPost) {
        this.isAttentionPost = false;
      }
    }
  },
};
</script>
<style scoped>
.custom-fileUpload {
  padding: 15px;
  background-color: rgb(255, 255, 255);
  border: 1px dashed rgb(0,0,0, 0.3);
  border-radius: 8px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

#img-delIcon i {
  color: #B40008;
}

.custom-fileUpload .node-image-uploader img {
  max-width: 25px;
  display: block;
}

.custom-fileUpload .node-image-uploader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.custom-fileUpload span.upload-caption {
  font-weight: 500;
}
</style>
