import Vue from "vue";
import Vuelidate from "vuelidate";
import { i18n } from "./plugins/i18n";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router/";
import store from "@/store/";
import { toUsFormat } from "@/directives";
import "./plugins/base";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import EmojiPickerPlugin from "vue-emoji-picker";
import timezone from "moment-timezone";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import "./filters/index";
import ImageUploader from 'vue-image-upload-resize';

Vue.config.productionTip = false;

// Global Components and Directives
Vue.directive("to-us-format", toUsFormat);

Vue.use(Vuelidate);

Vue.component("EmojiPicker", EmojiPickerPlugin);
Vue.use(EmojiPickerPlugin);
Vue.use(ImageUploader);

Vue.filter("DateTimezoneFilter", function (value) {
  const user = store.getters["user/getUser"];
  let userTimeZone = "America/Los_Angeles";
  if (user && user.user_detail && user.user_detail.timezone) {
    userTimeZone = user.user_detail.timezone;
  }
  if (!value) return "";
  value = value.toString();
  const utc = timezone.utc(value).toDate();
  const localTime = timezone(utc).tz(userTimeZone).format("MM/DD/YY h:mmA");
  return localTime;
});

Vue.filter("datefilter", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MMMM D, YYYY");
});

Vue.use(CKEditor);

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
